import { Container } from "../../atoms/container/Container";
import DoctorSelect from "../../atoms/doctorSelect/DoctorSelect";
import { Input } from "../../atoms/input/Input";
import { Text } from "../../atoms/text/Text";
import { DoctorCard } from "../../molecules/doctorCard/DoctorCard";
import style from "./doctorConsult.module.scss";
import { Flex, Typography } from "antd";
import { PhoneNumberCard } from "../../molecules/phoneNumberCard/PhoneNumberCard";
import { ShareProfileButton } from "../../molecules/shareProfileButton/ShareProfileButton";
import { useDoctorConsult } from "../../../hooks/useDoctorConsult/UseDoctorConsult";
import { AiOutlineSend } from 'react-icons/ai';
// @ts-ignore
import ReactFileReader from "react-file-reader";
import { Button } from "antd";
import { IoShareSocialOutline } from "react-icons/io5";
import { usePaymentStatus } from "../../../hooks/usePaymentStatus/usePaymentStatus";
import { PaymentButton } from "../paymentButton/PaymentButton";
const { Text: TextAnt } = Typography;
export const DoctorConsult = () => {
  const {
    handleDoctorChange,
    doctorData,
    doctorName,
    setDoctorName,
    doctorNumber,
    handleDoctorPhoneNumber,
    handleShareProfile,
    isBtnLoading,
    getTicketData,
    handlePrescriptionDoc,
    uploading,
    handleViewCurrentDocument,
    handlePrescriptionSend,
    HandleDoctorRoasters,
    doctorRosters,
    handleDoctorMobileNo,
    handlePatientMobileNo,
    sendMeetingLink,
    sendMeetingLoading,
    sendDoctorConsultPaymentLink,
    
  } = useDoctorConsult();
  //eslint-disable-next-line
  const { paymentBtnStatus_Doctor, sendPaymentLink } = usePaymentStatus();
  // console.log('paymentBtnStatus_Doctor:', paymentBtnStatus_Doctor);  
  return (
    <>
      <Container className={style.wrapper}>
        <Container className={style.spacing}>
          <Text text="Doctor Consultation" className={style.title} />
          <PaymentButton component="Doctor-Consult" paymentBtnStatus={paymentBtnStatus_Doctor} style={style.link_btn} createPaymentLink={sendDoctorConsultPaymentLink} sendPaymentLink={() => sendPaymentLink("doctor-consultation")} />
          <DoctorSelect doctors={doctorRosters} onClick={HandleDoctorRoasters} onChange={handleDoctorChange} />
          {doctorData ? (
            (
              <div>
                <DoctorCard
                  doctorName={`Dr. ${doctorData?.doctorName}`}
                  specialty={doctorData.pocoDoctor ? "PocoDoctor" : "Emergency Specialist"}
                  number={doctorData?.mobileNo}
                  startTime={doctorData.startTime}
                  endTime={doctorData.endTime}
                />
              </div>
            )
          ) : (
            <></>
          )}
          <Container className={style.meetingLinkContainer}>
            <input placeholder="Doctor's Number" onChange={handleDoctorMobileNo} className={style.input}></input>
            <input placeholder="Patient's Number" onChange={handlePatientMobileNo} className={style.input}></input>
            <Button className={style.meetingLinkBtn} onClick={sendMeetingLink} disabled={getTicketData?.ticketStatus === "New" || getTicketData?.ticketStatus === "Completed" ? true : false || sendMeetingLoading} loading={sendMeetingLoading}><IoShareSocialOutline /> Send Meeting Link</Button >
          </Container>
          <Text text="OR" className={style.center} />
          {!getTicketData?.doctorInfo && (
            <Container className={style.input_box}>
              <Input
                className={style.input}
                value={doctorName}
                searchValue={setDoctorName}
                placeholder="Doctor Name"
              />
              <Input
                className={style.input}
                value={doctorNumber}
                searchValue={handleDoctorPhoneNumber}
                placeholder="Doctor Number"
              />
            </Container>
          )}
          {getTicketData?.doctorInfo && (
            <Container className={style.doctor_filled}>
              <div className={style.doctor_det}>
                <Text text="Doctor's Name :" />
                <Text text={getTicketData?.doctorInfo?.fullName} />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Text text="Doctor's Contact No :" />
                <div style={{ display: "inline-flex", width: "auto" }}>
                  <PhoneNumberCard
                    componentName="doctorCard"
                    number={getTicketData?.doctorInfo?.mobile}
                  />
                </div>
              </div>
            </Container>
          )}
          {!getTicketData?.doctorInfo && (
            <Container className={style.btn_wrap}>
              <ShareProfileButton
                onClick={handleShareProfile}
                btnText={isBtnLoading ? "Loading" : "Share Patient’s Profile"}
              />
            </Container>
          )}
          {getTicketData?.doctorInfo && <Container>

            {getTicketData?.doctorInfo?.clinicalNotes[0]?.url ? (
              <>
                <>
                  <Button
                    loading={uploading}
                    style={{ marginTop: "10px" }}
                    onClick={handleViewCurrentDocument}
                  >
                    View Document
                  </Button>
                  <Button
                    icon={<AiOutlineSend />}
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    disabled={
                      getTicketData?.doctorInfo?.clinicalNotes?.length && getTicketData?.ticketStatus !== "Completed" ? false : true
                    }
                    loading={isBtnLoading}
                    onClick={handlePrescriptionSend}
                  >
                    Send
                  </Button>
                </>
              </>
            ) : (
              <>
                <ReactFileReader
                  fileTypes={[".pdf"]}
                  base64={true}
                  multipleFiles={false}
                  handleFiles={handlePrescriptionDoc}
                >
                  <Flex align="center" gap={"1rem"}>
                    <TextAnt className={style.warning} type="danger">Upload Only PDF</TextAnt>
                    <Button loading={uploading} className={style.Upload_btn}> Upload Document</Button>
                  </Flex>
                </ReactFileReader>
              </>
            )}
          </Container>}
          <></>
        </Container>
      </Container>
    </>
  );
};

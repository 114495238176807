import { useQueryClient } from "@tanstack/react-query";
import { paymentNotifySse } from "../../http";
import { ShowAlert } from "../../utils/showAlert";
import { currentDateTime } from "../../utils/helper";
import { getTicket, updateTicket } from "../../pages/actions/actions";
import { useDispatch } from "react-redux";
import { UseGetStoreData } from "../getStoreData/UseGetStoreData";


export const useSseTrigger = () => {
    const dispatch = useDispatch();
    const { getTicketData } = UseGetStoreData()
    const queryClient = useQueryClient();
    // console.log('getTicketData:---top', getTicketData);
    const connectSse = async () => {

        await queryClient.invalidateQueries(
            {
                queryKey: ['getOrdersByTicketId', getTicketData._id],
                exact: true,
                refetchType: 'all',
            },
        );
        const eventSourceOptions = {
            withCredentials: true, // Include credentials (cookies) with the request
        };
        const eventSource = new EventSource(paymentNotifySse, eventSourceOptions);
        eventSource.addEventListener(
            "open",
            () => {
                console.log("SSE opened!");
            },
            false
        );
        eventSource.addEventListener(
            "message",
            async (e) => {
                let data = JSON.parse(e.data);
                console.log('data:', data);
                // console.log('getTicketData:', getTicketData)
                if (data.message === "Payment update successful") {
                    const res = await getTicket({ ticketId: getTicketData?._id, navigate: "", dispatch });
                    const oldTicketData = res?.data?.notes;
                    ShowAlert("success", `${data?.paymentFor} payment Successful` || "payment Successful")
                    const payload = {
                        notes: [...oldTicketData, {
                            date: currentDateTime(),
                            message: `Payment_Successful for ${data?.paymentFor} `
                        }]
                    }
                    // console.log('payload:-----here', payload)
                    await updateTicket(getTicketData?._id, payload, dispatch);
                    // eventSource.close();
                    await queryClient.invalidateQueries(
                        {
                            queryKey: ['getOrdersByTicketId', getTicketData._id],
                            exact: true,
                            refetchType: 'all',
                        },
                    );

                } else {
                    ShowAlert("warning", `${data?.paymentFor} payment ${data.status}` || "payment Failure")
                    const payload = {
                        notes: [...getTicketData?.notes, {
                            date: currentDateTime(),
                            message: `Payment_Failed for ${data?.paymentFor} `
                        }]
                    }
                    // console.log('payload:-----failure', payload)
                    await updateTicket(getTicketData._id, payload, dispatch);
                    // eventSource.close();
                    await queryClient.invalidateQueries(
                        {
                            queryKey: ['getOrdersByTicketId', getTicketData._id],
                            exact: true,
                            refetchType: 'all',
                        },
                    );
                }
            },
            false
        );
        eventSource.addEventListener(
            "error",
            (e: any) => {
                // console.error("Error: ");
            },
            false
        );
        return () => {
            eventSource.close();
        };
    }
    return {
        connectSse
    }
}








import React from "react";
import { Form, Row, Col, Typography } from "antd";
import style from "./login.module.scss";
import { useCustomLogin } from "../../hooks/useCustomLogin/UseCustomLogin";
import MicrosoftLogin from "../../components/organisms/microSoftLogin/MicrosoftLogin";
const { Text } = Typography;
export const Login: React.FC = () => {
  const { onFinish, onFinishFailed } = useCustomLogin()

  return (
    <Row justify="center" align="middle" className={style.loginContainer}>
      <Col xs={24} sm={16} md={12} lg={10} xl={8}>
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={style.loginForm}
        >
          <div style={{ marginBottom: "1rem", textAlign:"center"}}>
            <Text style={{ fontWeight: 500, fontSize: "1.5rem" }}>
              Admin Login
            </Text>
          </div>
          <div>
            <MicrosoftLogin/>
          </div>
        </Form>

      </Col>
    </Row>
  );
};
import { Container } from "../../atoms/container/Container";
import { Text } from "../../atoms/text/Text";
import { Button, Flex, Input, Select } from "antd";
import style from "../../organisms/doctorConsult/doctorConsult.module.scss"
import { usePaymentLink } from "../../../hooks/usePaymentLink/usePaymentLink";
import './PaymentLink.css';

export const PaymentLink = ({ component }: any) => {
    const { amount, error, number, handleSendPaymentLink, email, handleCollectMobileNumber, handleCollectEmail, handleCatchAmount,
        handleSelectChange, benList, isLoading, ambulanceProvidersList, handleProviderChange, handleAmbulanceTypeChange,
        ambulanceType, ambulanceProviderId, isEditable, handleAmountBlur, handleAmountEditing,selectedDetails,doctorId,doctorPayment,handleAmountEditingForDoctor,handleCatchAmountForDoctor } = usePaymentLink({ component });

        
        
     
    return (
        <Container>
            {component==="Ambulance"?
            <Flex vertical gap={12}>
            {/* // selection of Ambulance Provider */}
            {/* options={ambulanceProvidersList.map((provider: any) => ({ label: provider.name, value: provider.id }))} */}
            <Select
                size={"middle"}
                placeholder="Please Select Ambulance Provider"
                style={{ width: '100%' }}
                options={[...ambulanceProvidersList.map((provider: any) => ({ label: provider.name, value: provider._id }))]}
                onChange={handleProviderChange}
                value={ambulanceProviderId}
                allowClear={true}
            >
            </Select>
            <Select
                size={"middle"}
                placeholder="Please Select Ambulance Type"
                style={{ width: '100%' }}
                options={[{label:'Basic Life Support',value:'BLS'},{label:'Advance Life Support',value:'ALS'}]}
                onChange={handleAmbulanceTypeChange}
                value={ambulanceType}
                allowClear={true}
            >
            </Select>
            <Input value={amount ? '₹ ' + amount : '' }
                allowClear={true}
                placeholder="₹ Ambulance Fare"
                onChange={handleCatchAmount}
                maxLength={10}
                onDoubleClick={ handleAmountEditing } 
                onBlur={ handleAmountBlur }
                readOnly={!isEditable}    
                className={isEditable ? 'input-focused' : 'input-blurred'}
            />

            <Select
                mode="tags"
                size={"middle"}
                placeholder="Please select"
                style={{ width: '100%' }}
                options={benList.map((elem) => ({ label: elem.name + "-" + elem.id+"-"+elem.mobile, value: elem.id }))}
                onChange={handleSelectChange}
                maxCount={1}
                allowClear={true}
                value={selectedDetails.map((elem:any) => ({ label: elem.name + "-" + elem.id+"-"+elem.mobile, value: elem.id }))}
            >
            </Select>
            <Flex justify="center" >
                <Text text="or" />
            </Flex>
            <Input value={number || ""} allowClear={true} onChange={handleCollectMobileNumber} maxLength={10} placeholder="Please Enter Mobile" />
            <Flex justify="center" >
                    <Text text="Optional" />
                </Flex>
            <Input status={error ? 'error' : ''} type="email" value={email || ""} allowClear={true} onChange={handleCollectEmail} placeholder="Please Enter Email" />
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <Button className={style.link_btn} loading={isLoading} onClick={handleSendPaymentLink}>Send Payment Link</Button>
        </Flex>:

        //      Drop down for doctor consultation fee payment
        
             <Flex vertical gap={12}>
                {/* // selection of Ambulance Provider */}
                {/* options={ambulanceProvidersList.map((provider: any) => ({ label: provider.name, value: provider.id }))} */}
               
                
                <Input value={doctorId}
                    allowClear={true}
                    readOnly={true}    
                
                />
              
                <Input value={  doctorPayment  }
                    allowClear={true}
                    maxLength={10}
                    onChange={handleCatchAmountForDoctor}
                    onDoubleClick={ handleAmountEditingForDoctor } 
                    onBlur={ handleAmountBlur }
                    readOnly={!isEditable}    
                    className={isEditable ? 'input-focused' : 'input-blurred'}
                />

                <Select
                    mode="tags"
                    size={"middle"}
                    placeholder="Please select"
                    style={{ width: '100%' }}
                    options={benList.map((elem) => ({ label: elem.name + "-" + elem.id+"-"+elem.mobile, value: elem.id }))}
                    onChange={handleSelectChange}
                    maxCount={1}
                    allowClear={true}
                    value={selectedDetails.map((elem:any) => ({ label: elem.name + "-" + elem.id+"-"+elem.mobile, value: elem.id }))}
                >
                </Select>
                <Flex justify="center" >
                    <Text text="or" />
                </Flex>
                <Input value={number || ""} allowClear={true} onChange={handleCollectMobileNumber} maxLength={10} placeholder="Please Enter Mobile" />
                <Flex justify="center" >
                    <Text text="Optional" />
                </Flex>
                <Input status={error ? 'error' : ''} type="email" value={email || ""} allowClear={true} onChange={handleCollectEmail} placeholder="Please Enter Email" />
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <Button className={style.link_btn} loading={isLoading} onClick={handleSendPaymentLink}>Send Payment Link</Button>
            </Flex>
            }
            
        </Container>
    );
}